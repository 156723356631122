import React from "react";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";
import api from "../../services/api";
import MaskedInput from "react-text-mask";
import Select from "react-select";

import './Register.scss';

class About extends React.Component {
  state = {
    formFields: {},
    lgpd: false,
    submitted: false,
    submitError: false,
    selectedOptions: [],
  };

  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  onInputChange = selected => {
    let arr = [];
    this.setState({ selectedOptions: selected });

    for (let i = 0; i < selected.length; i++) {
      const element = selected[i];
      arr.push(element.value);
    }

    if(selected.length === 0){
      arr = [];
    }

    this.setState({
      formFields: {
        ...this.state.formFields,
        "interesses": arr,
        origin: "hotsite",
      },
    })
  };

  handleForm = async (e) => {
    e.preventDefault();

    if (this.props.global.lgpd && !this.state.lgpd) {
      return this.setState({ submitError: "É necessário aceitar os termos!" });
    }

    if (
      this.state.formFields.convite &&
      this.state.formFields.convite !== "R2BPARTNER"
    ) {
      return this.setState({ submitError: "Código convite inválido" });
    }

    this.setState({ submitError: false });

    let user = { ...this.state.formFields };

    user.extra = {};

    Object.entries(user).forEach((item) => {
      if (
        item[0] !== "name" &&
        item[0] !== "email" &&
        item[0] !== "password" &&
        item[0] !== "extra"
      ) {
        user.extra[item[0]] = item[1];
        delete user[item[0]];
      }
    });

    user._id = user.email;
    if (user.extra && user.extra.telefone)
      user.extra.telefone = user.extra.telefone.replace(/[^\d]+/g, "");

    if (user.extra && user.extra.cpf)
      user.extra.cpf = user.extra.cpf.replace(/[^\d]+/g, "");

    await api.put("database/registration__c", user, {
      headers: {
        Authorization:
          "Basic " +
          window.btoa(
            process.env.REACT_APP_APIKEY +
              ":" +
              process.env.REACT_APP_PUBLIC_SECRETKEY
          ),
      },
    });

    this.setState({ submitted: true });
    document.getElementById("contactForm").reset();
  };

  onHideSuccess = () => {
    this.setState({ submitted: false });
  };

  successMessage = () => {
    const successMessageTxt = this.props.global.register.successMessage ? this.props.global.register.successMessage : 'Inscrição realizada com sucesso!';
    if (this.state.submitted) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          <span dangerouslySetInnerHTML={{ __html: successMessageTxt }}></span>
          <button type="button" className="close" onClick={this.onHideSuccess}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  };

  errorMessage = () => {
    if (this.state.submitError) {
      return (
        <div
          className="alert alert-danger  alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          {this.state.submitError}
          <button type="button" className="close" onClick={this.onHideSuccess}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  };

  render() {
    return (
      <section id="enrollment" className="pricing-area ptb-120 bg-image">
        <div className="container">
          <div className="section-title">
            <h2 className="titleMain">Inscreva-se</h2>
            <span className="subTitleMain">Garanta a sua vaga</span>

            <LaxDiv text="Inscreva-se" dataPreset="driftLeft" />

            <div className="bar"></div>
          </div>
          <div className="mx-auto col-lg-8 col-md-12">
            {this.props.global.register.email && !this.props.global.register.button && <form onSubmit={this.handleForm} id="contactForm">
              <label className="mb-3">{this.props.global.txtInscricao}</label>

              <div className="row">
                {this.props.global.register.attributes.map((item) =>
                  item.name !== "login" &&
                  item.name !== "password" &&
                  item.name !== "telefone" &&
                  item.name !== "cpf" &&
                  item.type !== "select" ? (
                    <div key={item.name} className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor={item.name}>
                          {item.title}
                          {item.required ? "*" : ""}
                        </label>
                        <input
                          type={item.type}
                          className="form-control"
                          name={item.name}
                          id={item.name}
                          onChange={(e) =>
                            this.setState({
                              formFields: {
                                ...this.state.formFields,
                                [item.name]: e.target.value,
                              },
                            })
                          }
                          required={item.required}
                          data-error="Please enter your name"
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                  ) : item.name === "telefone" ? (
                    <div key={item.name} className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor={item.name}>
                          {item.title}
                          {item.required ? "*" : ""}
                        </label>
                        <MaskedInput
                          guide={false}
                          keepCharPositions={false}
                          showMask={false}
                          mask={[
                            "(",
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          type={item.type}
                          className="form-control"
                          name={item.name}
                          id={item.name}
                          onChange={(e) =>
                            this.setState({
                              formFields: {
                                ...this.state.formFields,
                                [item.name]: e.target.value,
                              },
                            })
                          }
                          required={item.required}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                  ) : item.name === "cpf" ? (
                    <div key={item.name} className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor={item.name}>
                          {item.title}
                          {item.required ? "*" : ""}
                        </label>
                        <MaskedInput
                          guide={false}
                          keepCharPositions={false}
                          showMask={false}
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/
                          ]}
                          type={item.type}
                          className="form-control"
                          name={item.name}
                          id={item.name}
                          onChange={(e) =>
                            this.setState({
                              formFields: {
                                ...this.state.formFields,
                                [item.name]: e.target.value,
                              },
                            })
                          }
                          required={item.required}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                  ) : item.type === "select" ? (
                    <div key={item.name} className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor={item.name}>
                          {item.title}
                          {item.required ? "*" : ""}
                        </label>
                        <select
                          required={item.required}
                          className="form-control"
                          onChange={(e) =>
                            this.setState({
                              formFields: {
                                ...this.state.formFields,
                                [item.name]: e.target.value,
                              },
                            })
                          }
                        >
                          <option value="">Selecione uma opção</option>
                          {item.static_dropdown.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}

                {this.props.global.interestList.length > 0 && (
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-group">
                      <label htmlFor="interesses">
                        {!!this.props.global.nameInterestList ? this.props.global.nameInterestList : 'Interesses'}
                      </label>
                      <div style={{zIndex: "2", position: "relative"}}>
                        <Select
                          options={this.props.global.interestList}
                          isMulti
                          isClearable
                          placeholder={'Selecione'}
                          onChange={this.onInputChange}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {this.props.global.lgpd && (
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="check"
                        onChange={(e) =>
                          this.setState({ lgpd: e.target.checked })
                        }
                      />
                      <label className="form-check-label" htmlFor="check">
                        {this.props.global.lgpdText} <br />
                        <a
                          href={this.props.global.lgpdFile.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Termos e política de privacidade
                        </a>
                      </label>
                    </div>
                  </div>
                )}

                {/* {this.props.global.lgpd && (
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        Check me out
                      </label>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                )} */}

                {/* <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="email">Email*</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            email: e.target.value,
                          },
                        })
                      }
                      data-error="Please enter your email"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="number">Telefone*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="number"
                      id="number"
                      required={true}
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            phone: e.target.value,
                          },
                        })
                      }
                      data-error="Please enter your number"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="name">Empresa*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="empresa"
                      id="empresa"
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            empresa: e.target.value,
                          },
                        })
                      }
                      data-error="Please enter your name"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="name">Cargo*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="cargo"
                      id="cargo"
                      data-error="Please enter your name"
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            cargo: e.target.value,
                          },
                        })
                      }
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="name">Código convite</label>
                    <input
                      type="text"
                      className="form-control"
                      name="codigo"
                      id="codigo"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div> */}

                <div className="col-lg-12 col-md-12">
                  <button type="submit" className="btn">
                    Enviar
                  </button>
                  <div id="msgSubmit" className="h3 text-center">
                    {this.successMessage()}
                  </div>
                  <div id="msgSubmit" className="h3 text-center">
                    {this.errorMessage()}
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </form>}
          </div>
          {!this.props.global.register.email && this.props.global.register.button && 
            <div>
              <a type="button" className="btn" href={this.props.global.register.linkRegistrationButton} target="_blank" rel="noopener noreferrer">
                {this.props.global.register.textRegistrationButton}
              </a>
            </div>
          }
        </div>
      </section>
    );
  }
}

export default About;
