import React from "react";

class Footer extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="single-footer-widget">
                <h3>Endereço do evento</h3>
                <span>
                  <i className="icofont-calendar"></i>{" "}
                  {this.props.global.dateEvent}
                </span>

                <p className="location">
                  <i className="icofont-google-map"></i>{" "}
                  <a
                    href={this.props.global.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.global.url.replace(/(^\w+:|^)\/\//, "")}
                  </a>
                </p>

                {/* <Link to="#" className="contact-authority">
                                    <i className="icofont-phone"></i> Contact Our Authority
                                </Link> */}
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="single-footer-widget">
                <h3>Nossas Redes Sociais</h3>
                <p>Acompanhe as novidades em nossas Redes Sociais!</p>

                <ul className="social-links">
                  {this.props.social.facebook && (
                    <li>
                      <a
                        className="facebook"
                        href={this.props.social.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                  )}

                  {this.props.social.twitter && (
                    <li>
                      <a
                        className="twitter"
                        href={this.props.social.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                  )}

                  {this.props.social.instagram && (
                    <li>
                      <a
                        className="instagram"
                        href={this.props.social.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-instagram"></i>
                      </a>
                    </li>
                  )}

                  {this.props.social.linkedin && (
                    <li>
                      <a
                        className="linkedin"
                        href={this.props.social.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-linkedin"></i>
                      </a>
                    </li>
                  )}

                  {this.props.social.youtube && (
                    <li>
                      <a
                        className="youtube"
                        href={this.props.social.youtube}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-youtube"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="single-footer-widget">
                <h3>Quer participar como Palestrante ou Expositor?</h3>
                <p>
                  <a href="#contact">Fale com o organizador</a>
                </p>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="copyright-area">
                {/* <div className="logo">
                                    <Link to="index.html">
                                        <img src={require("../../assets/images/logo.png")} alt="logo" />
                                    </Link>
                                </div>
                                <ul>
                                    <li><Link to="#">Blog</Link></li>
                                    <li><Link to="#">Contact</Link></li>
                                    <li><Link to="#">Ticket</Link></li>
                                    <li><Link to="#">Venue</Link></li>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                </ul> */}
                <p>
                  Copyright <i className="icofont-copyright"></i>2021 INOVA3D. All
                  rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
