import React from 'react';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

class MainBanner extends React.Component {
  state = {
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
    isOpen: false,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <ModalVideo
          channel='youtube'
          isOpen={this.state.isOpen}
          videoId='cRXm1p-CNyk'
          onClose={() => this.setState({ isOpen: false })}
        />

        <div className='main-banner item-bg1' style={{backgroundImage: "url(" + this.props.global.imageBanner + ")"}}>
          <div className='d-table'>
            <div className='d-table-cell'>
              <div className='container'>
                <div className='main-banner-content'>
                  <div dangerouslySetInnerHTML={{ __html: this.props.global.titleBanner }}></div>
                  <div dangerouslySetInnerHTML={{ __html: this.props.global.descriptionBanner }}></div>
                  <div dangerouslySetInnerHTML={{ __html: this.props.global.dateOfEventBanner }}></div>
                  {/* <ul>
                    <li>
                      <i className='icofont-compass'></i>{' '}
                      <a
                        href='http://www.run2biz.funifier.com/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        run2biz.funifier.com
                      </a>
                    </li>
                    <li>
                      <i className='icofont-calendar'></i> 17-18 Mar, 2021
                    </li>
                  </ul> */}

                  {/* <div className="button-box">
                                        <Link to="#" className="btn btn-primary">Buy Tickets Now!</Link>
                                        <Link 
                                            onClick={e => {e.preventDefault(); this.openModal()}}
                                            to="#" 
                                            className="video-btn popup-youtube"
                                        >
                                            <i className="icofont-ui-play"></i> Watch Pormo Video
                                        </Link>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="event-countdown countdown1">
                        <div id="timer">
                            <div id="days">{this.state.days} <span>Days</span></div>
                            <div id="hours">{this.state.hours} <span>Hours</span></div>
                            <div id="minutes">{this.state.minutes} <span>Minutes</span></div>
                            <div id="seconds">{this.state.seconds} <span>Seconds</span></div>
                        </div>
                    </div> */}

          <div className='shape1'>
            <img
              src={require('../../assets/images/shapes/1.png')}
              alt='shape1'
            />
          </div>

          <div className='shape2 rotateme'>
            <img
              src={require('../../assets/images/shapes/2.png')}
              alt='shape2'
            />
          </div>

          <div className='shape3 rotateme'>
            <img
              src={require('../../assets/images/shapes/3.png')}
              alt='shape3'
            />
          </div>

          <div className='shape4'>
            <img
              src={require('../../assets/images/shapes/4.png')}
              alt='shape4'
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MainBanner;
