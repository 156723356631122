import React from 'react';
import { Link, withRouter } from 'react-router-dom';
 
class Navigation extends React.Component {

    state = {
        collapsed: true,
        isOpen: false
    };

     // eslint-disable-next-line
    constructor(props) {
      super(props);
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount() {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
                // window.history.pushState("", document.title, window.location.pathname);
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

    componentDidUpdate(nextProps) {
        if (this.props.match.path !== nextProps.match.path) {
            // this.onRouteChanged();
        }

        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
          anchor.addEventListener('click', function (e) {
              e.preventDefault();
      
       
      
              document.querySelector(this.getAttribute('href')).scrollIntoView({
                  behavior: 'smooth'
              });
          });
      });
    }

    onRouteChanged = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render(){
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        return (
            <header id="header" className="header-area">
                <div id="navbar" className="elkevent-nav">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/">
                                <img src={this.props.global} alt="logo" />
                            </Link>

                            <button 
                                onClick={this.toggleNavbar} 
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" 
                                aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <a 
                                            href="#header"
                                            onClick={this.toggleNavbar} 
                                            className="nav-link"
                                        >
                                            Home
                                        </a>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <a 
                                            href="#about"
                                            onClick={this.toggleNavbar} 
                                            className="nav-link"
                                        >
                                            Sobre o evento
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a 
                                            href="#schedule"
                                            className="nav-link"
                                            onClick={this.toggleNavbar}
                                        >
                                            Programação 
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a 
                                            href="#enrollment"
                                            className="nav-link"
                                            onClick={this.toggleNavbar}
                                        >
                                            Inscrição 
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a 
                                            href="#sponsors"
                                            className="nav-link" 
                                            onClick={this.toggleNavbar}
                                        >
                                            Patrocinadores 
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a 
                                            href="#contact" 
                                            className="nav-link" 
                                            onClick={this.toggleNavbar}
                                        >
                                            Fale Conosco
                                        </a>
                                    </li>
                                </ul>

                                {/* <div className="others-option">
                                    <ul>
                                        <li>
                                            <NavLink 
                                                to="/pricing-1" 
                                                className="btn btn-primary" 
                                                onClick={this.toggleNavbar}
                                            >
                                                Buy Ticket
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}
 
export default withRouter(Navigation);