import React from "react";
import "isomorphic-fetch";
import api from "../../services/api";
import MaskedInput from "react-text-mask";

import './Contact.scss'

class Contact extends React.Component {
  state = {
    submitting: false,
    submitted: false,
    buttonState: "",
    formFields: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const data = this.state.formFields;

    data["phone-number"] = data.phone.replace(/[^\d]+/g, "");

    delete data.phone;

    await api.post("database/contact_us__c", data, {
      headers: {
        Authorization:
          "Basic " +
          window.btoa(
            process.env.REACT_APP_APIKEY + ":" + process.env.REACT_APP_PUBLIC_SECRETKEY
          ),
      },
    });

    this.setState({ submitted: true });

    let formFields = Object.assign({}, this.state.formFields);
    formFields.name = "";
    formFields.email = "";
    formFields.phone = "";
    formFields.message = "";
    this.setState({ formFields });
  };

  nameChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.name = e.target.value;
    this.setState({ formFields });
  };

  emailChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.email = e.target.value;
    this.setState({ formFields });
  };

  phoneChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.phone = e.target.value;
    this.setState({ formFields });
  };

  textChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.message = e.target.value;
    this.setState({ formFields });
  };

  onHideSuccess = () => {
    this.setState({ submitted: false });
  };

  successMessage = () => {
    if (this.state.submitted) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          <strong>Obrigado!</strong> Sua mensagem foi enviada com sucesso!
          <button type="button" className="close" onClick={this.onHideSuccess}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <section id="contact" className="contact-area ptb-120 bg-image">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-4 col-md-6">
                                <div className="contact-box">
                                    <div className="icon">
                                        <i className="icofont-phone"></i>
                                    </div>
                                    
                                    <div className="content">
                                        <h4>Phone / Fax</h4>
                                        <p>(+021) 245522455</p>
                                        <p>(+000) 245522455</p>
                                    </div>
                                </div>
                            </div> */}

              <div className="col-lg-6 col-md-6">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-email"></i>
                  </div>

                  <div className="content">
                    <h4>E-mail</h4>
                    <p>{this.props.global.emailSupport}</p>
                    {/* <p>support@evnia.com</p> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-3">
                <div className="contact-box h-100">
                  <div className="icon">
                    <i className="icofont-world"></i>
                  </div>

                  <div className="content">
                    <h4>Endereço do evento</h4>
                    <p>
                      <a
                        href={this.props.global.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.props.global.url.replace(/(^\w+:|^)\/\//, "")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row h-100 align-items-center contact-form">
              <div className="col-lg-4 col-md-12">
                <div className="leave-your-message">
                  <h3 className="subTitle">Fale Conosco</h3>
                  <p>
                    Acompanhe as novidades pelas redes sociais! Se tiver dúvidas
                    ou precisar mais informações envie uma mensagem para nossa
                    Equipe! Será um prazer conversar com você.
                  </p>

                  <div className="stay-connected">
                    <h3>Acompanhe!</h3>
                    <ul>
                      {this.props.social.facebook && (
                        <li>
                          <a
                            href={this.props.social.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icofont-facebook"></i>
                            <span>Facebook</span>
                          </a>
                        </li>
                      )}

                      {this.props.social.twitter && (
                        <li>
                          <a
                            href={this.props.social.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icofont-twitter"></i>
                            <span>Twitter</span>
                          </a>
                        </li>
                      )}

                      {this.props.social.instagram && (
                        <li>
                          <a
                            href={this.props.social.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icofont-instagram"></i>
                            <span>Instagram</span>
                          </a>
                        </li>
                      )}

                      {this.props.social.linkedin && (
                        <li>
                          <a
                            href={this.props.social.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icofont-linkedin"></i>
                            <span>Linkedin</span>
                          </a>
                        </li>
                      )}

                      {this.props.social.youtube && (
                        <li>
                          <a
                            href={this.props.social.youtube}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="icofont-youtube"></i>
                            <span>Youtube</span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-12">
                <form onSubmit={this.onSubmit} id="contactForm">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Nome*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          required={true}
                          data-error="Please enter your name"
                          value={this.state.formFields.name}
                          onChange={this.nameChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="email">Email*</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          required={true}
                          data-error="Please enter your email"
                          value={this.state.formFields.email}
                          onChange={this.emailChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="number">Telefone*</label>
                        <MaskedInput
                          guide={false}
                          keepCharPositions={false}
                          showMask={false}
                          mask={[
                            "(",
                            /[1-9]/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /[1-9]{1}?/,
                          ]}
                          type="text"
                          className="form-control"
                          name="number"
                          id="number"
                          required={true}
                          data-error="Please enter your number"
                          value={this.state.formFields.phone}
                          onChange={this.phoneChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="message">Mensagem*</label>
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="4"
                          required={true}
                          data-error="Write your message"
                          value={this.state.formFields.message}
                          onChange={this.textChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button type="submit" className="btn btn-primary">
                        Enviar mensagem
                      </button>
                      <div id="msgSubmit" className="h3 text-center hidden">
                        {this.successMessage()}
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Contact;
